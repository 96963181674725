import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import styles from '../styles'

const TopElement = styled.div`
  margin: -2em -2em 2em -2em;
  padding: 2em;
  background: ${styles.lavenderLight};
`

const Container = styled.div`
  padding: 10% auto;

  p {
    color: ${styles.dustyDark};
    padding: 10% auto;
  }
`

const IndexPage = () => (
  <Layout>
    <TopElement><h3>michfarr.com</h3></TopElement>
    <Container>
      <p>I’m a web developer with a passion for the technologies that drive the web.</p>
      <p>My focus is crafting clear, reuseable code which is well tested for the benefit of both my colleagues and my own peace of mind.</p>
    </Container>
  </Layout>
)

export default IndexPage
